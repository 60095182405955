import React from "react";
import Layout from "../../Components/layout";
import GetItems from "../../Components/getItems";
import { Seo } from "../../Components/seo";


export default function Hoodies() {
  return (
    <Layout>
      <GetItems search="Men’s Hoodie" title="Men's Hoodies" />
    </Layout>
  )
}
export const Head = () => (<Seo pathname={'./mens/hoodies'} title="STM Quality Men's Hoodies: Positivity and Awareness" description={"Shopping for men's hoodies to spread positivity and awareness and promote Stronger Than Medicine (STM)."} />)